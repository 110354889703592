.home {
    background-color: #eaeaea;
    color: #000;

    .homeBoxShadow {
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    }

    .myTableSectionHeader {
        font-size: 16px;
        margin: 15px 0px 10px;
    }

    .myTableContainer {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 14px 15px;
    }

    .linkLike {
        color: #005eff;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .mainToolBar {
        // background-color: #fff6;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(0px);
        color: #fff;
        grid-column: span 2;
        padding: 8px;
        position: fixed;
        transition: background-color 0.5s, backdrop-filter 0.5s;
        width: calc(100% - 16px);
        z-index: 100;

        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50px auto 70px;

        // &:hover {
        //     background-color: rgba(0, 0, 0, 0.1);
        // }

        &.menuOpen {
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(4px);
        }

        .titleBar {
            line-height: 23px;
            text-align: center;

            span {
                cursor: pointer;
                margin: 0px 5px;
                padding: 0px 5px;
            }
        }

        .mainMenuButton, .logoutButton {
            // background-color: #ccc;
            border-radius: 5px;
            cursor: pointer;
            display: inline-block;
            font-weight: 200;
            height: 24px;
            text-align: center;

            font-size: 16px;
            line-height: 22px;
            width: 70px;

            &:hover {
                background-color: #5555;
            }
        }

        .mainMenuButton {
            width: 60px;
        }
    }

    .mainMenu {
        // background-color: #fff;
        background-color: rgba(0, 0, 0, 0.2);
        // background-color: rgba(40, 40, 40, 0.9);
        // box-shadow: 0px 8px 6px rgb(0 0 0 / 20%);
        backdrop-filter: blur(4px);
        opacity: 0;
        pointer-events: none;
        position: relative;
        transition: background-color 0.5s, opacity 0.4s, transform 0.5s;
        transform: translateX(-100%);

        &.menuOverlay {
            height: calc(100% - 40px);
            left: 0px;
            position: fixed;
            top: 40px;
            width: 200px;
            z-index: 100;
        }

        &.menuOpen {
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 1;
            pointer-events: auto;
            transform: translateX(0px);
        }

        .menuItem {
            // color: #000;
            color: #fff;
            cursor: pointer;
            display: block;
            padding: 6px 10px;
            text-decoration: none;

            &:hover, &.selected {
                background-color: #ccc;
            }

            &:nth-child(1)  { &:hover, &.selected { background-color: rgba(100, 149, 237, 0.7); } }
            &:nth-child(2)  { &:hover, &.selected { background-color: #8B4513; } }
            &:nth-child(3)  { &:hover, &.selected { background-color: #1E90FF; } }
            &:nth-child(4)  { &:hover, &.selected { background-color: #FF8F60; } }
            // &:nth-child(1) { &:hover, &.selected { background-color: rgba(186, 85, 211, 0.8); } }
            &:nth-child(5)  { &:hover, &.selected { background-color: rgba(220, 20, 60, 0.8); } }
            &:nth-child(6)  { &:hover, &.selected { background-color: rgba(34, 171, 34, 0.8); } }
            &:nth-child(7)  { &:hover, &.selected { background-color: rgba(255, 0, 0, 1); } }
            &:nth-child(8)  { &:hover, &.selected { background-color: #20B2AA; } }
            &:nth-child(9)  { &:hover, &.selected { background-color: rgb(28,  107,  45); } }
            &:nth-child(10) { &:hover, &.selected { background-color: #FF8C00; } }
            // &:nth-child(1) { &:hover, &.selected { background-color: #2E8B57; } }
            &:nth-child(11) { &:hover, &.selected { background-color: #FFD700; } }
            &:nth-child(12) { &:hover, &.selected { background-color: rgba(186, 85, 211, 0.8); } }

            &.loginButton {
                bottom: 0px;
                left: 0px;
                right: 0px;
                position: absolute;

                &:hover, &.selected {
                    background-color: rgba(34, 171, 34, 0.8);
                }
            }
        }
    }

    .homeContentContainer {
        display: grid;
        grid-template-rows: 1fr;
        min-height: 100dvh;
        width: 100%;

        &.logsOpen:not(.logsFullScreen) {
            grid-template-rows: 1fr 1fr;
        }

        .homeContent {
            overflow: auto;
            // padding: 10px 15px;

            .contentHeader {
                font-size: 20px;
            }
        }
    }
}