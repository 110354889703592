@keyframes loadingSpin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loadingContainer {
    height: 100%;
    min-height: 120px;
    min-width: 120px;
    position: relative;
    width: 100%;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-content: center;
    align-content: center;

    &.fixed {
        position: fixed;
    }

    &.text {
        .loadingDiv {
            display: none;
        }
    }

    .loadingDiv {
        animation-name: loadingSpin;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        aspect-ratio: 1;
        border: #ddd 10px solid;
        border-top-color: #57a1fc;
        // border-bottom-color: #57a1fc;
        border-radius: 50%;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
        opacity: 0.8;
        width: 100px;
    }

    .loadingMessage {
        padding: 10px 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
    }
}
