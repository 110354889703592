@keyframes fadeIn {
    from   { opacity: 0; }
    to  { opacity: 1; }
}

.photoGridContainer {
    background-color: #444;
    min-height: 100vh;

    .filterOptionsContainer {

        .gridSelectBox {
            display: inline-block;
            margin-right: 10px;
            width: 200px;

            // div.select {
            //     // background-color: #333;
            // }
        }
    }

    .photoGridGroup {
        margin-bottom: 10px;
        width: 100%;

        .gridGroupTitle {
            color: #fff;
            padding: 10px 10px 10px;
        }

        .photoGrid {
            display: grid;
            width: 100%;

            .gridPhoto {
                background-color: #888;
                border-radius: 10px;
                box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                transition: border-color 0.5s, transform 0.4s;

                // &:hover {
                //     // transform: scale(1.02);
                // }

                .gridPlaceholder {
                    background-color: #0006;
                    display: inline-block;
                    height: 100%;

                    animation-name: fadeIn;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: transform 0.4s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .viewPhotoContainer {
        background-color: #333;
        position: fixed;
        height: 100%;
        width: 100vw;
        top: 0px;
        left: 0px;
        z-index: 10;

        .viewPhotoCloseX {
            cursor: pointer;
            font-size: 26px;
            position: absolute;
            right: 15px;
            top: 51px;

            &:hover {
                color: #fff;
            }
        }

        .photoControls {
            background-color: #0006;
            border-radius: 10px;
            color: #fff;
            opacity: 0.2;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 0.5s;

            &:hover {
                opacity: 1;
            }

            div {
                cursor: pointer;
                font-size: 20px;
                float: left;
                padding: 10px 15px;

                &.playPause {
                    width: 22px;
                    text-align: center;
                }
            }
        }
    }
}
