.notifySnackbarsContainer {
    bottom: 20px;
    display: grid;
    font-family: myMono;
    grid-auto-rows: auto;
    grid-gap: 10px;
    height: 0px;
    // overflow: hidden;
    position: fixed;
    right: 20px;
    transition: height 0.5s;
    min-width: 300px;
    z-index: 1000;
}

.notifySnackbar {
    background-color: #54da58;
    border-radius: 10px;
    box-shadow: 2px 3px 10px 0px #aaa;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 51px;
    grid-template-rows: 100%;
    height: 50px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transform: translate(0%, 100%);
    transition: transform 0.3s, opacity 0.3s; // not using "top", because I use top later for dragging the box
    width: 100%;

    // @media (max-width: 550px) {
    //     max-width: calc(100% - 50px);
    // }

    .darkMode &, &.darkMode {
        background-color: #00770e;
        box-shadow: 2px 3px 10px 0px #111;
        color: #fff;
    }

    &.notifyError {
        background-color: #da5454;

        .darkMode &, &.darkMode {
            background-color: #770000;
            color: #fff;
        }
    }

    &.snackbarOpen {
        opacity: 1;
        transform: translate(0%, 0%);
    }

    &.snackbarClosing {
        transform: translate(calc(100% + 30px), 0%);
    }

    .snackbarText {
        padding: 15px;
    }

    .closeX {
        border-left: rgba(124, 124, 124, 40%) solid 1px;
        border-radius: 0px 10px 10px 0px;
        color: #000;
        cursor: pointer;
        font-size: 28px;
        height: 20px;
        line-height: 16px;
        padding: 15px;
        // position: absolute;
        // right: 15px;
        text-align: center;
        // top: 15px;
        width: 20px;
        z-index: 100;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .timerBar {
        background-color: rgba(100, 100, 100, 0.1);
        bottom: 0px;
        height: 100%;
        left: 0px;
        position: absolute;
        transition: width 5s;
        transition-timing-function: linear;
        width: 0px;
    }
    &.snackbarOpen .timerBar {
        width: 100%;
    }
    &.darkMode .timerBar {
        background-color: rgba(255, 255, 255, 0.1);
    }
}