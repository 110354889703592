.noContextMenu {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    min-width: 200px;
    padding: 10px 0px;
    position: fixed;
    z-index: 1000;

    .darkMode & {
        background-color: #444;
    }

    .noContextGroupSeperator {
        border-top: 1px solid #eee;
        height: 0px;
        margin: 3px 0px;
    }

    .noContextAction {
        color: #000;
        cursor: pointer;
        font-size: 15px;
        height: 15px;
        line-height: 15px;
        padding: 7px 15px;
        position: relative;
        white-space: nowrap;

        .darkMode & {
            color: #fff;
        }

        &.noContextHover {
            background-color: #eee;

            .darkMode & {
                background-color: #555;
            }
        }

        &.noContextDisabled, &.noContextDisabled .noContextActionIcon {
            color: #aaa;
            cursor: default;
        }

        .noContextActionIcon {
            color: #777;
            margin-right: 10px;
        }

        .subMenuRightArrow {
            color: #999;
            position: absolute;
            right: 7px;
            top: 6px;
        }
    }
}
