.myTable {
    // display: grid;
    // grid-gap: 1px;

    .headerRow {
        // font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .tableRowsContainer {
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tableRow {
        display: grid;
        font-size: 13px;
        grid-gap: 1px;
        margin-bottom: 1px;
        width: 100%;

        &.compactRow {
            display: block;
            overflow: hidden;
            transition: height .5s;

            .compactHeadersContainer {
                position: relative;
                height: 50px;
                background-color: #fff;
                width: calc(100% - 1px);

                & > div {
                    position: absolute;
                }

                .compactHeader {
                    font-size: 14px;
                    top: 10px;
                    left: 10px;
                }

                .compactSubHeader {
                    color: #666;
                    font-size: 11px;
                    top: 28px;
                    left: 20px;
                }

                .compactRightHeader {
                    top: 10px;
                    right: 25px;
                }

                .compactRightSubHeader {
                    color: #666;
                    font-size: 11px;
                    top: 28px;
                    right: 25px;
                }

                .compactDropArrow {
                    cursor: pointer;
                    top: 17px;
                    right: 10px;
                }
            }
        }

        & .tableCell {
            overflow: auto;
            padding: 3px 6px;
            white-space: nowrap;

            &::-webkit-scrollbar {
                display: none;
            }

            &.clickActionCell {
                cursor: pointer;
            }

            &.emptyCell {
                background-color: #fff !important;

                .darkMode & {
                    background-color: transparent;
                }
            }

            &.money {
                padding: 3px 5px;

                .positiveNumber {
                    padding-right: 5px;
                }
            }
        }
    }
}