.appLayout {
    background-color: #fff;
    // height: calc(100% - 40px);
    // padding: 20px;
    // position: fixed;
    width: 100%;
    // width: calc(100% - 40px);

    &.darkMode {
        background-color: #282c34;
    }
}