$filmStripHeight: 150px;

.photoContainer {
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 3s;
    width: 100%;

    &.visible {
        opacity: 1;
        pointer-events: auto;
    }

    .photo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .photoBackground {
        filter: blur(30px) saturate(0.75);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .photoInfo {
        background-color: #0001;
        border-radius: 10px;
        bottom: 10px;
        color: #fff;
        cursor: default;
        font-size: 12px;
        left: 10px;
        padding: 8px 10px;
        position: absolute;
        transition: background-color 0.5s, backdrop-filter 0.5s;

        &:hover {
            background-color: #0006;
            backdrop-filter: blur(4px);
        }

        .isMobile &.top {
            bottom: unset;
            top: 50px;
        }

        .ratingStarsContainer {

            .ratingStar {
                cursor: pointer;
            }
        }
    }
}
