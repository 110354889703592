.sectionContainer {
    // border-bottom: 1px solid #ddd;
    margin-bottom: 10px;

    .sectionLabel {
        background-color: #e2e2e2;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 5px;
        padding: 3px 5px;
    }
}
