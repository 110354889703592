.loginContainer {
    background-color: #eaeaea;
    position: fixed;

    &:not(.dialog) {
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
    }

    &.dialog {
        background-color: transparent;
        box-shadow: none;

        .closeX {
            transform: translate(-15px, 4px);
        }
    }

    .darkMode & {
        background-color: #333;
    }

    .login {
        background-color: #fff;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        border-radius: 15px;
        // height: 210px;
        left: 50%;
        padding: 10px 15px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 300px;

        .darkMode & {
            background-color: #444;
        }

        // label {
        //     display: block;
        //     font-size: 16px;
        //     margin: 10px 15px 20px;
        //     text-align: center;

        //     input {
        //         background-color: transparent;
        //         border: none;
        //         border-bottom: 2px solid #aaa;
        //         display: block;
        //         font-size: 14px;
        //         margin-top: 10px;
        //         padding: 5px 10px;
        //         width: calc(100% - 20px);

        //         &:focus {
        //             border-bottom: 2px solid #205AC9;
        //             outline: none;
        //         }
        //     }
        // }

        // .button {
        //     background-color: #ddd;
        //     color: #000;
        //     cursor: pointer;
        //     font-size: 15px;
        //     margin: 0px 15px;
        //     padding: 6px 0px;
        //     text-align: center;
        //     width: calc(100% - 30px);

        //     &:hover {
        //         background-color: #bbb;
        //     }
        // }
    }
}
