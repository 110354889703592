label.myInput {
    display: block;
    font-size: 14px;
    // margin: 0px 5px 12px;
    margin: 0px 0px 10px;
    position: relative;

    &:not(.checkboxContainer) {
        height: 50px;
    }

    .darkMode & {
        color: #fff;
    }

    & > span {
        display: inline-block;
        height: 16px;
        padding: 0px 8px 2px;
    }

    .tooltip {
        background-color: #ddd;
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        height: 16px;
        line-height: 16px;
        margin-left: 8px;
        position: relative;
        text-align: center;
        width: 16px;

        &:hover {
            background-color: #ccc;
        }

        .tooltipText {
            background-color: #444;
            border-radius: 10px;
            color: #fff;
            display: inline-block;
            font-size: 12px;
            left: 25px;
            padding: 3px 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 300px;
            white-space: pre-line;
            z-index: 10;

            &::after {
                background-color: #444;
                content: '';
                height: 10px;
                left: -8px;
                position: absolute;
                top: calc(50% - 1px);
                transform: rotate(45deg) translateY(-50%);
                width: 10px;
            }
        }
    }

    input.inputBox, select, div.select, div.datePickerContainer {
        background-color: transparent;
        // border: none;
        border: 2px solid #aaa;
        border-radius: 10px;
        color: inherit;
        display: block;
        font-size: 14px;
        height: 17px !important; // if things are ever not aligning vertically, check this. used for div select
        padding: 5px 10px;
        width: calc(100% - 24px);

        .darkMode & {
            border-color: #666;
        }

        &:focus {
            border: 2px solid #6495ED;
            outline: none;
        }
    }

    input.inputBox {
        height: 16px;
    }

    &.percent::after {
        content: '%';
        bottom: 8px;
        position: absolute;
        right: 35px;
    }

    select {
        height: 30px;
        width: 100%;
    }

    div.select {
        cursor: pointer;
        position: relative;

        .selectArrow {
            color: #666;
            font-size: 22px;
            line-height: 20px;
            position: absolute;
            right: 10px;
            top: 1px;
        }

        .itemsContainer {
            background-color: #fff;
            // border: 2px solid #aaa;
            border: 2px solid #6495ED;
            border-radius: 10px;
            // box-shadow: 3px 5px 10px -5px #444;
            left: -2px;
            max-height: 250px;
            overflow: auto;
            // padding: 5px 0px;
            position: absolute;
            top: -2px;
            width: 100%;
            z-index: 10;

            &::-webkit-scrollbar {
                display: none;
            }

            .darkMode & {
                background-color: #444;
            }

            div {
                height: 17px;
                padding: 5px 10px;

                &.selected, &:hover {
                    background-color: #eee;

                    .darkMode & {
                        background-color: #555;
                    }
                }

                &:first-child {
                    border-radius: 8px 8px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 8px 8px;
                }
            }
        }
    }

    div.pillScrollContainer {
        background-color: transparent;
        color: inherit;
        display: block;
        font-size: 14px;
        height: 31px;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        div.pillContainer {
            display: inline-block;

            div {
                background-color: transparent;
                border: 2px solid #aaa;
                border-radius: 10px;
                float: left;
                height: 17px;
                margin-right: 5px;
            }

            .singlePill, .doublePill {
                &.pillFocus {
                    border: 2px solid #6495ED;
                }

                input {
                    border: none;
                    font-size: 14px;
                    margin: 0px;
                    padding: 0px;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .singlePill {
                padding: 5px 10px;
                width: 85px;

                input {
                    width: 80px;
                }
            }

            .doublePill {
                display: grid;
                grid-template-columns: auto auto;
                grid-gap: 5px;
                padding: 5px 10px;
                width: 145px;

                input {
                    width: 80px;

                    &:last-child {
                        width: 50px;
                    }
                }
            }

            .addPillButton {
                color: #999;
                cursor: pointer;
                font-size: 24px;
                line-height: 13px;
                padding: 5px 10px;

                &:hover {
                    border-color: #6495ED;
                    color: #6495ED;
                }
            }
        }
    }

    div.pillPickerContainer {
        display: grid;
        grid-gap: 5px;

        .pillPick {
            background-color: transparent;
            border: 2px solid #aaa;
            border-radius: 10px;
            color: #555;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            height: 17px !important;
            padding: 5px 10px;
            text-align: center;

            &.selected {
                border-color: #518ded;
                // color: #2766cb;
                color: #0e4eb5;
                background-color: #ddeaff;
            }
        }
    }

    div.dateContainer {
        display: grid;
        grid-template-columns: 1fr 65px 90px;
        grid-gap: 5px;

        label {
            margin: 0px;
        }
    }

    div.datePickerContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        position: relative;

        .currentDate {
            cursor: pointer;
        }

        .datePickerArrow {
            color: #666;
            font-size: 22px;
            line-height: 20px;
            position: absolute;
            right: 10px;
            top: 1px;
        }

        .datePicker {
            background-color: #fff;
            border: 2px solid #aaa;
            border-radius: 10px;
            left: -2px;
            min-height: 30px;
            overflow: hidden;
            position: absolute;
            top: -2px;
            width: 100%;
            z-index: 10;

            .datePickerHeader {
                display: grid;
                grid-template-columns: 25px 1fr 25px;

                div {
                    padding: 4px 0px;
                    text-align: center;

                    &.datePickerMonthArrow {
                        cursor: pointer;
                    }
                }
            }

            .datePickerDaysContainer {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-auto-rows: 26px;
                // grid-gap: 5px;
                // padding: 0px 0px 8px;

                .datePickerDay {
                    padding: 3px;
                    text-align: center;

                    &:not(.datePickerBlank):not(.datePickerLabel), &.selected {
                        cursor: pointer;
                    }

                    &:hover:not(.datePickerBlank):not(.datePickerLabel), &.selected {
                        background-color: #ddd;
                    }
                }
            }
        }
    }

    div.timeContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 75px;
        grid-gap: 5px;

        label {
            margin: 0px;
        }
    }

    div.dateTimeContainer {
        display: grid;
        grid-template-columns: 1fr 180px;
        grid-gap: 5px;

        label {
            margin: 0px;

            .dateContainer {
                grid-template-columns: 1fr 55px 70px;
            }

            .timeContainer {
                grid-template-columns: 1fr 1fr 60px;
            }
        }
    }

    &.switchLabel {
        margin: 0px;

        div.switch {
            background-color: #ddd;
            border-radius: 8px;
            cursor: pointer;
            height: 16px;
            position: relative;
            transition: background-color .25s;
            width: 28px;

            div {
                background-color: #fff;
                border-radius: 6px;
                height: 12px;
                left: 2px;
                position: absolute;
                top: 2px;
                transition: left .25s;
                width: 12px;
            }

            &.switchOn {
                background-color: #0c0;

                div {
                    left: 14px;
                }
            }
        }
    }

    &.checkboxContainer {
        cursor: pointer;

        input[type='checkbox'] {
            height: 15px;
            margin: 0px;
            transform: translate(4px, 2px);
            width: 15px;
        }
    }

    &.fileDropContainer {
        border: #ddd dashed 2px;
        border-radius: 15px;
        display: inline-block;
        height: auto;
        padding: 15px 10px 10px;
        width: calc(100% - 24px);

        &.fileDragActive {
            background-color: #eee;
        }

        & > input[type='file'] {
            display: none;
        }

        .fileDropMessage {
            font-size: 14px;
            margin-bottom: 10px;
            text-align: center;
        }

        .buttonContainer {
            margin: 0px;
        }
    }

    &.buttonContainer {
        height: 30px;

        div.button {
            background-color: #ddd;
            border: 2px solid #ddd;
            border-radius: 10px;
            color: #000;
            cursor: pointer;
            font-size: 15px;
            padding: 4px 0px;
            text-align: center;
            width: calc(100% - 4px);

            &:hover {
                background-color: #bbb;
                border-color: #bbb;
            }

            .darkMode & {
                background-color: #333;
                border-color: #333;
                color: #fff;

                &:hover {
                    background-color: #444;
                    border-color: #444;
                }
            }

            &:focus {
                border: 2px solid #6495ED;
                outline: none;
            }
        }
    }
}