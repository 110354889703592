.mainToolBar {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 70px auto 70px;
    padding: 8px;
    position: fixed;
    transition: background-color 0.5s, backdrop-filter 0.5s;
    top: 0px;
    width: calc(100% - 16px);
    z-index: 100;

    .titleBar {
        line-height: 23px;
        text-align: center;

        span {
            cursor: pointer;
            margin: 0px 5px;
            padding: 0px 5px;
        }
    }

    .mainMenuButton, .logoutButton {
        // background-color: #ccc;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        font-weight: 200;
        height: 24px;
        text-align: center;
        transition: background-color 0.3s;

        font-size: 16px;
        line-height: 22px;
        width: 70px;
    }

    .mainMenuButton {
        width: 60px;
    }
}

.mainMenu {
    // box-shadow: 0px 8px 6px rgb(0 0 0 / 20%);
    pointer-events: none;
    position: relative;
    transition: background-color 0.5s, opacity 0.4s, transform 0.5s;
    transform: translateX(-100%);

    &.menuOverlay {
        height: calc(100% - 40px);
        left: 0px;
        position: fixed;
        top: 40px;
        width: 200px;
        z-index: 100;
    }

    &.menuOpen {
        pointer-events: auto;
        transform: translateX(0px);
    }

    .menuItem {
        cursor: pointer;
        display: block;
        height: 20px;
        padding: 6px 10px;
        text-decoration: none;

        &:nth-child(1)  { &:hover, &.selected { background-color: rgba(100, 149, 237, 0.7); } }
        &:nth-child(2)  { &:hover, &.selected { background-color: #8B4513; } }
        &:nth-child(3)  { &:hover, &.selected { background-color: #1E90FF; } }
        &:nth-child(4)  { &:hover, &.selected { background-color: #FF8F60; } }
        // &:nth-child(1) { &:hover, &.selected { background-color: rgba(186, 85, 211, 0.8); } }
        &:nth-child(5)  { &:hover, &.selected { background-color: rgba(220, 20, 60, 0.8); } }
        &:nth-child(6)  { &:hover, &.selected { background-color: rgba(34, 171, 34, 0.8); } }
        &:nth-child(7)  { &:hover, &.selected { background-color: rgba(255, 0, 0, 1); } }
        &:nth-child(8)  { &:hover, &.selected { background-color: #20B2AA; } }
        &:nth-child(9)  { &:hover, &.selected { background-color: rgb(28,  107,  45); } }
        &:nth-child(10) { &:hover, &.selected { background-color: #FF8C00; } }
        // &:nth-child(1) { &:hover, &.selected { background-color: #2E8B57; } }
        &:nth-child(11) { &:hover, &.selected { background-color: #FFD700; } }
        &:nth-child(12) { &:hover, &.selected { background-color: rgba(186, 85, 211, 0.8); } }

        &.bottomButton {
            bottom: 0px;
            left: 0px;
            right: 0px;
            position: absolute;
        }
    }
}

/* ----------------------------- Menu Theme Colors ------------------------------*/
.mainToolBar.dark {
    // background-color: #fff6;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(0px);
    color: #fff;

    &.menuOpen {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
    }

    .mainMenuButton, .logoutButton {
        // background-color: #ccc;

        &:hover {
            background-color: #5555;
        }
    }
}
.mainMenu.dark {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    opacity: 0;

    &.menuOpen {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
    }

    .menuItem {
        color: #fff;

        // &:hover, &.selected {
        //     background-color: #ccc;
        // }

        &.loginButton {
            &:hover, &.selected {
                background-color: rgba(34, 171, 34, 0.8);
            }
        }
    }
}

.mainToolBar.lightSolid {
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0,0,0,.2);
    color: #000;

    // &.menuOpen {
    //     // background-color: rgba(0, 0, 0, 0.5);
    //     // backdrop-filter: blur(4px);
    // }

    .mainMenuButton, .logoutButton {
        // background-color: #ccc;

        &:hover {
            background-color: #ddd;
        }
    }
}
.mainMenu.lightSolid {
    background-color: #fff;
    box-shadow: 0px 6px 6px rgba(0,0,0,.2);
    color: #000;
    // opacity: 0;

    // &.menuOpen {
    //     background-color: rgba(0, 0, 0, 0.5);
    //     opacity: 1;
    // }

    .menuItem {
        color: inherit;

        &.loginButton {
            &:hover, &.selected {
                background-color: rgba(34, 171, 34, 0.8);
            }
        }
    }
}
