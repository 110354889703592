.alert {
    background-color: #fff;
    border-radius: 10px;
    // box-shadow: 3px 5px 15px -5px #000;
    box-shadow: 2px 3px 10px 0px #aaa;
    color: #000;
    left: 50%;
    max-height: calc(100% - 50px);
    max-width: 500px;
    min-width: 250px;
    min-height: 100px;
    opacity: 0;
    padding: 15px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, calc(-50vh - 100% - 10px));
    transition: transform 0.3s, opacity 0.3s; // not using "top", because I use top later for dragging the box
    z-index: 100;

    @media (max-width: 550px) {
        max-width: calc(100% - 50px);
    }

    .darkMode &, &.darkMode {
        // background-color: #484c54;
        // box-shadow: 5px 8px 10px #1a1a1a;
        // color: #ddd;

        background-color: #222;
        box-shadow: 2px 3px 10px 0px #111;
        color: #fff;
    }

    .alertButtonsContainer, .confirmButtonsContainer {
        margin-top: 20px;

        .okayButton, .cancelButton {
            margin-bottom: 0px;
        }
    }

    .confirmButtonsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
}