.jsonViewer {
    font-family: monospace;
    font-size: 12px;
    padding: 5px 10px;
    white-space: break-spaces;

    .objectPreview {
        padding: 1px 0px;

        .objectKey {
            color: #9b017e;
            cursor: pointer;
            font-weight: bold;
        }

        .objectDropdownArrow {
            color: #555;
            cursor: pointer;
            display: inline-block;
            font-size: 7px;
            margin-right: 5px;

            &.open {
                transform: rotate(90deg);
            }
        }

        .objectArrowSpacer {
            display: inline-block;
            width: 10px;
        }

        .string {
            // color: rgb(190, 0, 0);
            color: rgb(0, 140, 0);
        }

        .boolean, .number, .null {
            color: rgb(0, 0, 217);
        }

        .pointer {
            cursor: pointer;
        }
    }

    .darkMode & {
        .objectPreview {
            .objectKey {
                color: #ff15d3;
            }

            .string {
                color: #00d000;
            }

            .boolean, .number, .null {
                color: #048dff;
            }
        }
    }

    .objectItemsContainer {
        padding-left: 12px;
    }
}