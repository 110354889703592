$filmStripHeight: 150px;

// @keyframes fadeIn {
//     from   { opacity: 0; }
//     to  { opacity: 1; }
// }
@keyframes fadeIn {
    from   { background-color: #0000; }
    to  { background-color: #0006; }
}

.photoSlideshow {
    height: 100dvh;
    position: fixed;
    width: 100vw;

    .slideshowContainer {
        height: 100%;
        position: absolute;
        transition: height 0.5s;
        width: 100%;

        &.filmStripView {
            height: calc(100% - $filmStripHeight);
        }
    }

    .slideshowControls {
        background-color: #0006;
        border-radius: 10px;
        color: #fff;
        opacity: 0.2;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.5s;

        &:hover {
            opacity: 1;
        }

        div {
            cursor: pointer;
            font-size: 20px;
            float: left;
            padding: 10px 15px;

            &.playPause {
                width: 22px;
                text-align: center;
            }
        }
    }

    .filmStripContainer {
        bottom: -$filmStripHeight;
        height: $filmStripHeight;
        overflow-x: auto;
        overflow-y: hidden;
        position: absolute;
        transition: bottom 0.5s;
        white-space: nowrap;
        width: 100%;

        &.filmStripView {
            bottom: 0;
        }

        .filmStripPhoto {
            border: 5px transparent solid;
            cursor: pointer;
            display: inline-block;
            height: calc(100% - 10px);
            // margin: 5px 0px 5px 5px;
            margin: 0px -5px 0px 0px;
            transition: border-color 0.5s;

            &.selected {
                border: 5px #00a7ff solid;
                // margin: 0px -5px 0px 0px;
            }

            .filmStripPlaceholder {
                background-color: #0006;
                display: inline-block;
                height: 100%;

                animation-name: fadeIn;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }

            img {
                height: 100%;
            }
        }
    }
}