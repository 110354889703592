@font-face {
    font-family: myMono;
    /* font-weight: 300; */
    src: url('./fonts/ChivoMono-TestG.ttf') format('truetype');
}

@font-face {
    font-family: myMonoItalic;
    font-weight: 300;
    src: url('./fonts/ChivoMono-Italic-VariableFont_wght.ttf') format('truetype');
}
